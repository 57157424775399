import { OpenFr8LogoSvg } from "~/components/assets/svg/OpenFr8LogoSvg";
import {  Link, useLoaderData, useNavigate, useSubmit } from "@remix-run/react";
import { ActionFunction, LoaderFunctionArgs, json, redirect } from "@remix-run/node";
import { loginAuthenticator } from "~/.server/auth/auth";
import { commitSession, getSession } from "~/utils/session/session.server";
import { constants } from "~/utils/constants";
import { Button } from "~/components/ui/button";
import { Card, CardContent } from "~/components/ui/card";
import { ChevronDown, EyeIcon, EyeOffIcon, LockKeyhole } from "lucide-react";
import { toast } from "~/components/ui/use-toast";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "~/components/ui/form";
import InputController from "~/components/ui/custom_ui/inputController";
import CountryCodeSelect from "~/components/composed/common/countryCodeSelect";
import { Errors } from "~/components/composed/indent/createIndent/indentChunks";
import CampaignInit from "~/hooks/CampaignInit";

export const action: ActionFunction = async ({ request }) => {
  const url = new URL(request.url)
  const currentPath = url.pathname
  const search = url.search

  const user: any = await loginAuthenticator.authenticate('mobile-password', request, {
    failureRedirect: currentPath + search
  })

  let session = await getSession(request.headers.get("cookie"));
  session.set(loginAuthenticator.sessionKey, user);
  console.log({user})
  let headers = new Headers({ "Set-Cookie": await commitSession(session) });
  if (user?.page) return redirect(user.page, { headers })
    
  return redirect(constants.login, { headers })
};

export async function loader({ request }: LoaderFunctionArgs) {
  await loginAuthenticator.isAuthenticated(request, {
    successRedirect: '/app',
  });

  let session = await getSession(request.headers.get("cookie"));
  const authError = session.get(loginAuthenticator.sessionErrorKey);

  if (authError) {
    session.unset(loginAuthenticator.sessionErrorKey);
  }

  return json({ authError } as const, {
    headers: {
      "Set-Cookie": await commitSession(session),
    },
  });
}

const loginWithPinFormSchema = z.object({
  mobile : z.string().refine((val) => (/^\d+$/.test(val)), {
    message: 'Enter valid mobile number',
}),
  password : z.coerce.number({
    invalid_type_error:'Invalid password',
  }).refine(
    (val) =>(/^\d+$/.test(val.toString())),{
      message: 'Enter valid password', 
    }
  ),
  country_id : z.number({
    message : 'Country code is required'
  }).default(1),
  country_code : z.string({
    message : 'Country code is required'
  }).default('971')
})

const formDefaultValue = {
  mobile: '',
  password : undefined,
  country_id : 1,
  country_code : '971'
}

const GccLogin = () => {
  const { authError } = useLoaderData<typeof loader>()
  const navigate = useNavigate()
  const submit = useSubmit();
  const [showPassword,setShowPassword] = useState<Boolean>(false)
  const [showCountrySelectModal,setShowCountrySelectModal] = useState<boolean>()

  const form = useForm<z.infer<typeof loginWithPinFormSchema>>({
    defaultValues : formDefaultValue,
    mode: 'onChange',
    reValidateMode:'onBlur',
    resolver : zodResolver(loginWithPinFormSchema)
  })

  

  useEffect(() => {
    if (authError) {
      toast({
        variant: 'destructive',
        title: 'Oops!',
        description: `${authError?.message}`,
      })
      if(authError?.message?.includes('no user found')){
          navigate(constants.createAccount)
      }
    }
  }, [authError])
  
  type ValidationSchema = z.infer<typeof loginWithPinFormSchema>
  const onSubmit:SubmitHandler<ValidationSchema> = (values) => {
    submit(values,{
      method:'POST',
    })
  }
  const toggleShowPassword = () => setShowPassword(prev => !prev);

  const toggleCountryCode = () => setShowCountrySelectModal(prev => !prev)
  const countryCodeWatcher = form.watch('country_code') 
  const countryErrorMessage = form.formState.errors?.country_code?.message
  return (
    <div className="flex min-h-screen items-center  w-full justify-center px-6">
      <div className="w-full flex flex-col max-w-md gap-4">
        <Card>
          <CardContent>
            <div className="flex flex-col">
              <OpenFr8LogoSvg className="h-24 mb-2 self-center" />
              <p className="font-medium text-lg text-start px-4">UAE's largest truck & load booking platform</p>
              <p className="font-normal sm:text-sm text-xs text-start px-4 text-slate-400">Register and get truck or loads within minutes</p>
            </div>
          </CardContent>
        </Card>
        <Form
          {...form}
        >
          <form
            method="post"
            autoComplete="on"
            id="signInForm"
            className="flex flex-col justify-between max-h-max md:p-0 mt-4 gap-3"
            onSubmit={form.handleSubmit(onSubmit)}
          >
          <p className="font-medium text-2xl">Sign in</p>
          <div>
          <InputController
            control={form.control}
            name='mobile'
            autoComplete="mobile tel"
            type="tel"
            maxlength={10}
            className='ml-6'
            prefix={
              <div onClick={toggleCountryCode} className='ml-6 flex flex-row items-center cursor-pointer text-neutral-500 hover:font-bold'>
                    <p>+{countryCodeWatcher}</p>
                    <ChevronDown size={16}/>
                </div>
            }
            required
            placeholder="Enter Mobile Number"
            containerStyle={'h-12'}
            />
            { form.formState.errors.mobile ? null : <Errors error={countryErrorMessage}/>}
          </div>
          <InputController
            control={form.control}
            name='password'
            autoComplete="off"
            type={showPassword ? 'text' : 'password'}
            maxlength={4}
            prefix={<LockKeyhole size={18} className="text-gray-500" />}
            required
            placeholder="Enter pin"
            containerStyle={'h-12'}
            inputMode="numeric"
            suffix={
              showPassword ?
              (<EyeIcon size={18} onClick={toggleShowPassword}/> ):
             ( <EyeOffIcon onClick={toggleShowPassword} size={18}/>)
            }
          />
          <Link to={constants.forgotPassword} className="text-right ml-auto text-primary cursor-pointer w-fit">
            Forgot pin ?
          </Link>
          <Button
            variant={'default'} type='submit'>
            Continue
          </Button>
          <Link className="cursor-pointer text-center" to={constants.createAccount}>
          No account ? <span className="underline font-semibold text-primary">Sign up</span>
          </Link>
            {
              showCountrySelectModal ?
                <CountryCodeSelect
                  onSelectCountryCode={(value) => {
                    form.setValue('country_id', value.country_id)
                    form.clearErrors('country_code')
                  }}
                  onClose={toggleCountryCode}
                  selectedValue={form.getValues('country_code')}
                  open={showCountrySelectModal}
                /> : null
            }
          </form>
        </Form>
      </div>
      <CampaignInit />
    </div>
  );
}

export default GccLogin